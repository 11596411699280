@import '../assets/styles/globals';

.card.clickable {
  cursor: pointer;
  &:hover {
    box-shadow: var(--ld-shadow-hover);
  }
}


:root, .ld-header, :host(.ld-header){
  --ld-header-max-width: 80rem;
}

.navlink {
  color: var(--ld-col-neutral-500);
}
.navlink:hover, .navlink.active {
  color: var(--ld-col-neutral-900);
}

@import 'assets/styles/globals';
@import-normalize;
@tailwind base;
@tailwind components;

@layer components {
  .drop-zone-wrapper {
    @apply border-dashed border-rich-blue border-2 bg-sensitive-grey transform transition duration-150 ease-out hover:scale-101 hover:bg-sensitive-grey-dark active:bg-sensitive-grey-darker rounded-m;
  }
  .drop-zone-wrapper.dragging {
    @apply transform transition duration-150 ease-out scale-101 bg-sensitive-grey-dark;
  }
}

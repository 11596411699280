@import 'assets/styles/globals';
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .badge ld-loading {
    --ld-loading-size: 1rem !important;
  }
  ld-loading.ld-loading-black {
    --ld-loading-col: #000 !important;
  }
  ld-loading.ld-loading-white {
    --ld-loading-col: #fff !important;
  }
}

.ld-badge--error, .ld-badge--failed {
  --ld-badge-bg-col: var(--ld-col-rr-400);
}
.ld-badge--creating, .ld-badge--created, .ld-badge--submitted {
  --ld-badge-bg-col: var(--ld-col-vy-200);
}
.ld-badge--queued, .ld-badge--pending, .ld-badge--runnable {
  --ld-badge-bg-col: var(--ld-col-vy-200);
}
.ld-badge--running, .ld-badge--starting {
  --ld-badge-bg-col: var(--ld-col-rb-600);
}
.ld-badge--finished, .ld-badge--succeeded {
  --ld-badge-bg-col: var(--ld-col-rg-400);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html,
  body {
    height: 100%;
    line-height: 1.4;
    font-weight: 400;
    font-family: Lato, sans-serif;
  }

  main {
    display: block;
  }

  strong {
    font-weight: 900;
  }

  .overflow-anchor-none {
    overflow-anchor: none;
  }
  .ld-badge--warning {
    --ld-badge-bg-col: var(--ld-thm-warning);
    color: #000;
  }
}

@layer components {
  .tooltip {
    @apply card card-active break-words whitespace-normal max-w-[300px];
  }
}

.LD__TooltipWrapper {
  min-width: initial !important;
}

.LD__ModalContent.editor-modal > div:last-of-type {
  padding: 0 !important;
}

@import 'assets/styles/globals';
.sidebar__user {
  &-button-logout {
    svg {
      path {
        opacity: 0.75;
      }
    }

    &:hover {
      svg {
        path {
          opacity: 1;
        }
      }
    }

    &:active {
      svg {
        path {
          opacity: 0.9;
        }
      }
    }

    &:focus {
      svg {
        g {
          opacity: 1;
        }
      }
    }
  }
}

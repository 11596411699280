.ld-theme-lewis {
  --ld-thm-error-active: var(--ld-thm-bubblegum-error-active);
  --ld-thm-error-focus: var(--ld-thm-bubblegum-error-focus);
  --ld-thm-error-highlight: var(--ld-thm-bubblegum-error-highlight);
  --ld-thm-error-hover: var(--ld-thm-bubblegum-error-hover);
  --ld-thm-error: var(--ld-thm-bubblegum-error);
  --ld-thm-primary-active: var(--ld-col-vc-900);
  --ld-thm-primary-alpha-low: var(--ld-col-vc-alpha-low);
  --ld-thm-primary-alpha-lowest: var(--ld-col-vc-alpha-lowest);
  --ld-thm-primary-focus: var(--ld-col-vc-500);
  --ld-thm-primary-highlight: var(--ld-col-vc-010);
  --ld-thm-primary-hover: var(--ld-col-vc-600);
  --ld-thm-primary: var(--ld-col-vc-400);
  --ld-thm-secondary-active: var(--ld-col-rb-800);
  --ld-thm-secondary-focus: var(--ld-col-rb-400);
  --ld-thm-secondary-highlight: var(--ld-col-rb-010);
  --ld-thm-secondary-hover: var(--ld-col-rb-700);
  --ld-thm-secondary: var(--ld-col-rb);
  --ld-thm-success-active: var(--ld-thm-bubblegum-success-active);
  --ld-thm-success-focus: var(--ld-thm-bubblegum-success-focus);
  --ld-thm-success-highlight: var(--ld-thm-bubblegum-success-highlight);
  --ld-thm-success-hover: var(--ld-thm-bubblegum-success-hover);
  --ld-thm-success: var(--ld-thm-bubblegum-success);
  --ld-thm-warning-active: var(--ld-thm-bubblegum-warning-active);
  --ld-thm-warning-focus: var(--ld-thm-bubblegum-warning-focus);
  --ld-thm-warning-highlight: var(--ld-thm-bubblegum-warning-highlight);
  --ld-thm-warning-hover: var(--ld-thm-bubblegum-warning-hover);
  --ld-thm-warning: var(--ld-thm-bubblegum-warning);
}
